<template>
    <div class="flex flex-col py-7 items-center">
        <div class="flex w-full sm:w-[600px] px-3 lg:px-0 lg:w-[800px] overflow-hidden justify-center rounded-2xl">
            <div class="w-[242px] sm:w-[400px] lg:w-[600px]">
                <img src="../assets/necesidades-especiales/mascota.png" alt="silla"
                    class="h-64 w-full object-cover rounded-l-2xl">
            </div>
            <div class="flex w-[130px] sm:w-[300px] lg:w-[400px] h-64 bg-blue-500 items-center rounded-r-2xl">
                <p class="text-xs lg:text-xl font-serif font-bold text-blue-900 ml-3 lg:ml-7">
                    Mascotas a bordo
                </p>
            </div>
        </div>
        <div>
            <CardsNecesidades />
        </div>
    </div>
</template>

<script>
import CardsNecesidades from './CardsNecesidades.vue';

export default {
    name: 'PoliticasMascotas',
    components: {
        CardsNecesidades,
    }
}
</script>