<template>
    <section class="p-6 bg-gray-100">
      <h1 class="text-3xl font-semibold text-center mb-6">Prepara tu equipaje</h1>
      <p class="text-center text-lg mb-8">Viaja a tu medida con el equipaje que necesitas</p>
      
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <!-- Artículo Personal -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <div class="flex justify-center mb-4">
            <img src="../assets/articulo-personal.png" alt="Artículo personal" class="w-52 h-auto" />
          </div>
          <h2 class="text-xl font-semibold mb-2">Artículo personal</h2>
          <ul class="list-disc ml-5 space-y-2">
            <li>Medidas máximas: 45 x 35 x 20 cm (alto, largo y ancho).</li>
            <li>Asegúrate que puedas ubicarlo debajo del asiento delantero.</li>
            <li>
              En todas nuestras <a href="#" class="text-red-500">tarifas</a> podrás llevar un artículo personal gratis, conoce las
              <a href="#" class="text-red-500">condiciones</a>.
            </li>
          </ul>
        </div>
  
        <!-- Equipaje de Mano -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <div class="flex justify-center mb-4">
            <img src="../assets/equipaje-de-mano.png" alt="Equipaje de mano" class="w-52 h-auto" />
          </div>
          <h2 class="text-xl font-semibold mb-2">Equipaje de mano</h2>
          <ul class="list-disc ml-5 space-y-2">
            <li>Medidas máximas: 55 x 35 x 25 cm (alto, largo y ancho), incluyendo ruedas y manijas.</li>
            <li>Peso máximo: 10 kg.</li>
            <li>
              Incluido en todas las <a href="#" class="text-red-500">tarifas</a> a excepción de las tarifas basic y XS.
            </li>
            <li>
              Conoce más sobre el <a href="#" class="text-red-500">equipaje de mano</a>.
            </li>
          </ul>
        </div>
  
        <!-- Equipaje de Bodega -->
        <div class="bg-white p-6 rounded-lg shadow-lg">
          <div class="flex justify-center mb-4">
            <img src="../assets/equipaje-de-bodega.png" alt="Equipaje de bodega" class="w-52 h-auto" />
          </div>
          <h2 class="text-xl font-semibold mb-2">Equipaje de bodega</h2>
          <ul class="list-disc ml-5 space-y-2">
            <li>Medidas máximas: no debe exceder los 158 cm lineales (largo + ancho + alto).</li>
            <li>Peso máximo: 23 kg en Clase económica y 32 kg en business class.</li>
            <li>
              Las <a href="#" class="text-red-500">tarifas</a> classic, flex, business, L, M y XXL incluyen este equipaje.
            </li>
            <li>
              Conoce más sobre el <a href="#" class="text-red-500">equipaje de bodega</a>.
            </li>
          </ul>
        </div>
      </div>
    </section>
  </template>
<script>
export default {
    name: 'GestionEquipaje',
}
</script>