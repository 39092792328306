<template>
    <div class="bg-gray-900" @click="handleGlobalClick">
        <Navbar ref="navbar" @dropdown-opened="dropdownOpened = true" @dropdown-closed="dropdownOpened = false"
            @menu-opened="menuOpened = true" @menu-closed="menuOpened = false" />
        <main class="min-h-screen mt-[75px]">
            <router-view />
        </main>
        <FootPage />
    </div>
</template>

<script>
import Navbar from '@/components/NavBar.vue';
import FootPage from '@/components/FootPage.vue';

export default {
    components: {
        Navbar,
        FootPage,
    },
    data() {
        return {
            dropdownOpened: false,
            menuOpened: false,
        };
    },
    methods: {
        handleGlobalClick(event) {
            if ((this.dropdownOpened || this.menuOpened) && !this.$refs.navbar.$el.contains(event.target)) {
                this.$refs.navbar.closeDropdown();
                this.$refs.navbar.closeMenu();
            }
        },
    },
};
</script>