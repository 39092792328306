<template>
    <div class="bg-white p-8 flex justify-center items-start gap-8 scroll-mt-24" >
      <!-- Imagen del edificio -->
      <div class="w-full md:w-1/2 lg:w-1/3 overflow-hidden rounded-lg">
        <img src="https://via.placeholder.com/600x400" alt="Edificio Cochabamba" class="w-full h-full object-cover">
      </div>
  
      <!-- Información de contacto -->
      <div class="w-full md:w-1/2 lg:w-2/3">
        <h2 class="text-3xl font-bold text-gray-900 mb-6">COCHABAMBA</h2>
  
        <div class="space-y-6">
          <div class="border-b border-gray-300 pb-4">
            <h3 class="text-lg font-bold text-gray-800 mb-2">PREGRADO</h3>
            <p class="text-gray-600 mb-1">Av. Cap. Víctor Ustáriz Km. 6.5</p>
            <p class="text-yellow-500 font-semibold mb-2">Teléfono: +591 (4)4268287</p>
            <a href="#" class="flex items-center text-blue-700 font-bold">
              <span class="material-icons mr-1">place</span> CÓMO LLEGAR
            </a>
          </div>
  
          <div class="border-b border-gray-300 pb-4">
            <h3 class="text-lg font-bold text-gray-800 mb-2">ADMISIONES PREGRADO CBBA</h3>
            <p class="text-gray-600 mb-1">Av. Cap. Víctor Ustáriz Km. 6.5</p>
            <p class="text-yellow-500 font-semibold mb-2">Teléfono: +591 78362269</p>
            <a href="#" class="flex items-center text-blue-700 font-bold">
              <span class="material-icons mr-1">place</span> CÓMO LLEGAR
            </a>
          </div>
  
          <div class="border-b border-gray-300 pb-4">
            <h3 class="text-lg font-bold text-gray-800 mb-2">ADMISIONES POSTGRADO CBBA</h3>
            <p class="text-gray-600 mb-1">Av. Cap. Víctor Ustáriz Km. 6.5</p>
            <p class="text-yellow-500 font-semibold mb-2">Teléfono: +591 71411110</p>
            <a href="#" class="flex items-center text-blue-700 font-bold">
              <span class="material-icons mr-1">place</span> CÓMO LLEGAR
            </a>
          </div>
  
          <div>
            <h3 class="text-lg font-bold text-gray-800 mb-2">ADMISIONES FORMACIÓN CONTINÚA CBBA</h3>
            <p class="text-gray-600 mb-1">Av. Cap. Víctor Ustáriz Km. 6.5</p>
            <p class="text-yellow-500 font-semibold mb-2">Teléfono: +591 71411110</p>
            <a href="#" class="flex items-center text-blue-700 font-bold">
              <span class="material-icons mr-1">place</span> CÓMO LLEGAR
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OurDirecctions',
  };
  </script>
  
  <style scoped>
  /* Puedes agregar estilos personalizados aquí si es necesario */
  </style>