<template>
    <div class="bg-green-500">
        <h1>Requisitos kevin</h1>
    </div>
    </template>
    
    <script>
    export default {
        name: 'RequisitosViaje',
    }
    </script>