<template>
    <div class="mt-[-160px]">
        <div class="absolute bg-gray-900 top-0 left-0 right-0 h-[80px] z-10"></div>
        <div class="h-[570px]" id="home">
            <iframe src="https://tamep.transclicksolutions.com/flights/flight-search"
                class="w-full h-[645px] scale-75">
                Tu navegador no soporta iframes.
            </iframe>
        </div>
        <VideoTam />
        <CitySelector id="ofertas" />
        <NewDestinations id="proximos" />
        <OurDirections id="oficinas" />
    </div>
</template>

<script>
import VideoTam from './VideoTam.vue';
import CitySelector from './CitySelector.vue';
import NewDestinations from './NewDestinations.vue';
import OurDirections from './OurDirections.vue';

export default {
    name: 'HomePrincipal',
    components: {
        VideoTam,
        CitySelector,
        NewDestinations,
        OurDirections,
    },
};
</script>