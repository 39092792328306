<template>
    <div class="container mx-auto px-4 py-8">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="(card, index) in infoCards" :key="index"
                class="bg-blue-500 rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:transform hover:scale-105 flex mb-4">
                <div class="w-1/4 bg-blue-600">
                    <img :src="card.image" :alt="card.title" class="w-full h-full object-cover">
                </div>
                <div class="w-3/4 p-4">
                    <h2 class="text-xl font-bold text-white mb-2">{{ card.title }}</h2>
                    <p class="text-white">{{ card.content }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cocha3 from "../assets/cocha3.jpeg";
import cocha1 from "../assets/cocha1.jpeg";
import cocha2 from "../assets/cocha2.jpeg";

export default {
    data() {
        return {
            infoCards: [
                {
                    title: "¿Estoy embarazada, Qué necesito para viajar?",
                    content: "Si tiene un buen estado de salud puede viajar hasta las 28 semanas de gestación debiendo llenar el formulario médico. A partir de la semana 29 de gestación deberá presentar el certificado médico autorizando su viaje.",
                    image: cocha3
                },
                {
                    title: "¿Entre que edades se considera menor?",
                    content: "Se considera menor a pasajeros comprendidos entre 2 y 12 años.",
                    image: cocha3
                },
                {
                    title: "¿Cuales son los requisitos para que un menor viaje solo?",
                    content: "Presentar el respectivo permiso de viaje expedido por la defensoría de la niñez y adolecencia, además deberá llenar el formulario del menor no acompañado.",
                    image: cocha1
                },
                {
                    title: "¿Puedo viajar con bebes?",
                    content: "Puedes viajar con el bebé sobre tu regazo, el pagará el 10 por ciento de un pasaje normal, no se acepta más de un infante por adulto.",
                    image: cocha3
                },
                {
                    title: "¿A partir de que edad puedo acceder a la tarifa de 3ra edad?",
                    content: "Se considera 3ra edad a partir de los 60 años cumplidos, mostrando su carnet de indentidad o pasaporte.",
                    image: cocha2
                },
                {
                    title: "¿Que requisitos debo presentar para acceder al descuento por discapacidad?",
                    content: "Carnet de discapacidad que califique su discapacidad como grave o muy grave.",
                    image: cocha1
                },
                {
                    title: "¿Puedo viajar con mi mascota en la cabina del avion?",
                    content: "Si quiere viajar con su mascota, le ofrecemos el servicio que esta destinado unicamente para mascotas mayores a los 3 meses de hasta 5 kilos.",
                    image: cocha3
                }
            ]
        }
    },
    methods: {
        getImageUrl(image) {
            return `/assets/${image}`;
        }
    }
}
</script>