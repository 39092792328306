<template>
  <div class="absolute z-20 bg-white shadow-lg py-4 w-full">
    <!-- Layout para Ofertas y destinos -->
    <div v-if="menuType === 'Ofertas y destinos'" class="container mx-auto grid grid-cols-1 lg:grid-cols-4 gap-4">
      <!-- Columna 1 para el menú -->
      <div class="col-span-1 pl-4">
        <ul class="space-y-3 flex flex-col">
          <li class="text-gray-700">
            <a href="#" class="font-serif" @click.prevent="navigateTo('/', '#ofertas')">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Ofertas de vuelos
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
          <li class="text-gray-700">
            <a href="#" class="font-serif" @click.prevent="navigateTo('/', '#proximos')">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Proximos Rutas
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
          <li class="text-gray-700">
            <a href="#" class="font-serif" @click.prevent="navigateTo('/', '#oficinas')">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Nuestras Oficinas
                <span class="text-xl">➔</span>
              </div>
            </a>
          </li>
          <li class="text-gray-700">
            <a href="#" class="font-serif" @click="selectOption('Traslados')">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Web Check-in
                <span class="text-xl">
                  ➔
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <!-- Carrusel de imágenes para Ofertas y destinos -->
      <div class="col-span-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 pr-10">
        <div v-for="(image, index) in visibleImages" :key="index" class="relative">
          <img :src="image" alt="Imagen"
            class="w-full h-64 object-contain lg:object-fill rounded-lg transition-transform duration-400 ease-in-out">
        </div>
      </div>
    </div>

    <!-- Layout de dos columnas para Reservar, Tu reserva, y Información y ayuda -->
    <div v-else class="container sm:mx-4 lg:mx-9 grid grid-cols-2 gap-4">
      <!-- Columna 1: Opciones del menú -->
      <div class="col-span-3 sm:col-span-1 w-10/12 lg:w-3/4 pl-4">
        <ul class="space-y-2">
          <!-- Opciones para Reservar -->
          <li v-if="menuType === 'Reservar'"><a href="#" class="text-gray-700 hover:text-blue-500"
              @click="selectOption('Reservar ahora')">Reservar ahora</a></li>
          <!-- Opciones para Tu reserva -->
          <li v-if="menuType === 'Tu reserva'"><a href="#" class="text-gray-700 hover:text-blue-500"
              @click="selectOption('Ver mi reserva')">Ver mi reserva</a></li>
          <!-- Opciones para Información y ayuda -->
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/equipaje" @click="closeDropdown" class="text-gray-700 hover:text-blue-500">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Equipaje
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/centro-ayuda" class="text-gray-700 hover:text-blue-500" @click="closeDropdown">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Centro de ayuda
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/requisitos-viajar" @click="closeDropdown" class="text-gray-700 hover:text-blue-500">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Requisitos para viajar
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/necesidades-especiales" @click="closeDropdown" class="text-gray-700 hover:text-blue-500">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Necesidades especiales
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/vuelos-ninos-bebes" @click="closeDropdown" class="text-gray-700 hover:text-blue-500">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Vuelos con los niños y bebés
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
          <li v-if="menuType === 'Información y ayuda'">
            <router-link to="/mascotas-bordo" @click="closeDropdown" class="text-gray-700 hover:text-blue-500">
              <div class="flex justify-between mr-3 hover:text-blue-500">
                Mascotas a bordo
                <span class="text-xl">➔</span>
              </div>
            </router-link>
          </li>
        </ul>
      </div>

      <!-- Columna 2: Carrusel de imágenes -->
      <div class="col-span-2 sm:col-span-1 lg:col-span-1 px-10 relative">
        <img :src="visibleImage" alt="Imagen Carrusel"
          class="w-full h-64 object-fill rounded-lg transition-transform duration-400 ease-in-out">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuType: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      images: [
        require('@/assets/la-paz-1.png'),
        require('@/assets/la-paz-2.png'),
        require('@/assets/la-paz-3.png'),
        require('@/assets/santa-cruz-1.png'),
        require('@/assets/santa-cruz-2.png'),
        require('@/assets/santa-cruz-3.png'),
        require('@/assets/cobija-1.png'),
        require('@/assets/cobija-2.png'),
        require('@/assets/cobija-3.png'),
      ],
      carousels: {
        Reservar: [
          require('@/assets/reservar-1.jpeg'),
          require('@/assets/reservar-2.jpeg'),
          require('@/assets/reservar-3.jpeg'),
        ],
        'Tu reserva': [
          require('@/assets/tu-reserva-1.jpeg'),
          require('@/assets/tu-reserva-2.jpeg'),
          require('@/assets/tu-reserva-3.jpeg'),
        ],
        'Información y ayuda': [
          require('@/assets/info-ayuda-1.jpeg'),
          require('@/assets/info-ayuda-2.jpeg'),
          require('@/assets/info-ayuda-3.jpeg'),
        ],
      },
      currentImageIndex: 0,
      intervalId: null, // Almacenar el ID del intervalo
    };
  },
  computed: {
    visibleImages() {
      return this.menuType === 'Ofertas y destinos'
        ? this.images.slice(this.currentImageIndex, this.currentImageIndex + 3)
        : [];
    },
    visibleImage() {
      return this.carousels[this.menuType]
        ? this.carousels[this.menuType][this.currentImageIndex]
        : null;
    }
  },
  methods: {
    // goToSection(hash) {
    //   console.log('Navegando hacia:', hash); // Verificar el hash
    //   this.$router.push({ path: '/', hash });
    // },
    startCarousel() {
      clearInterval(this.intervalId); // Limpiar cualquier intervalo anterior

      if (this.menuType === 'Ofertas y destinos') {
        this.intervalId = setInterval(() => {
          this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
        }, 5000);
      } else if (this.carousels[this.menuType]) {
        this.intervalId = setInterval(() => {
          const carouselLength = this.carousels[this.menuType].length;
          this.currentImageIndex = (this.currentImageIndex + 1) % carouselLength;
        }, 5000);
      }
    },
    selectOption(option) {
      this.$emit('option-selected', option);
      this.closeDropdown(); // Cerrar el dropdown después de seleccionar una opción
    },
    navigateTo(path, hash = '') {
      this.$router.push({ path, hash }).catch(() => {});
      this.closeDropdown();
    },
    closeDropdown() {
      this.$emit('close-dropdown');
    },
  },
  watch: {
    menuType() {
      this.currentImageIndex = 0; // Reiniciar el índice
      this.startCarousel(); // Iniciar el carrusel de nuevo
    }
  },
  mounted() {
    this.startCarousel(); // Iniciar carrusel cuando se monte el componente
  },
  beforeUnmount() {
    clearInterval(this.intervalId); // Limpiar el intervalo cuando se destruya el componente
  }
};
</script>

<style scoped></style>